<template>
  <div v-if="dataready">
    <div
      class="
        container-fluid
        header-content
        pos-relative
        height-res-tab-small-300 height-res-200
      "
    >
      <div class="container-standar pos-relative h-100 h-res-tab-small-80">
        <div class="pos-absolute bottom-10 margin-auto text-center w-100">
          <!-- <h4 class="text-white font-28 font-weight-6 mb-10">Data Kependudukan</h4> -->
          <h4 class="text-white font-35 font-weight-7 mb-20">
            DATA KESEHATAN PROVINSI PAPUA
          </h4>
          <a class="font-14 text-white font-weight-6" href="/">Home /</a>
          <a class="font-14 text-white" href="#">Data Pokok/</a>
          <a class="font-14 text-white" href="#">Data Kesehatan</a>
        </div>
      </div>
    </div>
    <div class="container-standar bg-white mt-40 pl-10 pr-10 pb-9 mb-10">
      <JiwaSummary />
      <section class="pb-4">
        <div>
          <ChartSummary />
        </div>
      </section>
      <section class="pb-4">
        <div class="row">
          <div class="col-md-12 d-inline-flex">
            <div class="shadow card pad-12 w-100">
              <span
                class="
                  font-18
                  text-primary text-center
                  pl-4
                  mb-10
                  font-weight-6
                "
              >
                <strong
                  >Program Bantuan Sosial Tahun
                  {{ new Date().getFullYear() }}</strong
                >
              </span>
              <Bansos />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import JiwaSummary from "../../../components/JiwaSummary";
import ChartSummary from "../../../components/ChartSummary";
import Bansos from "../data-kependudukan/components/bansos";
export default {
  components: {
    JiwaSummary,
    ChartSummary,
    Bansos,
  },
  name: "data-kependudukan",
  data() {
    return {
      selected1: null,
      options1: [
        { value: null, text: "Tahun 2022" },
        { value: "a", text: "Tahun 2022" },
      ],
      dataOAP: [],
      total_pria: 0,
      total_perempuan: 0,
      tabactive_1: true,
      tabactive_2: false,
      tabactive_3: false,
      tabactive_4: false,
      tabactive_5: false,
      tabactive_6: false,
      tabactive_7: false,
      tabactive_8: false,
      tabactive_9: false,
      tabactive_10: false,
      tabactive_11: false,
      tabactive_12: false,
      tabactive_13: false,
      tabactive_14: false,
      total_kk: 0,
      total_jiwa: 0,
      partisipasi_sekolah: {},
      data_provinsi: [],
      data_district: [],
      dataready: false,
    };
  },
  async mounted() {
    this.$store.commit("SET_BG", true);
    await this.getTotalJenisPenduduk();
    await this.getDataPartisipasiSekolah();
    await this.getDataProvinsi();
    await this.persebaranOAP();
    this.dataready = true;
  },
  methods: {
    chekedfunction(newVal) {
      this.tabactive_1 = false;
      this.tabactive_2 = false;
      this.tabactive_3 = false;
      this.tabactive_4 = false;
      this.tabactive_5 = false;
      this.tabactive_6 = false;
      this.tabactive_7 = false;
      this.tabactive_8 = false;
      this.tabactive_9 = false;
      this.tabactive_10 = false;
      this.tabactive_11 = false;
      this.tabactive_12 = false;
      this.tabactive_13 = false;
      this.tabactive_14 = false;
      if (newVal == 1) {
        this.tabactive_1 = true;
      }
      if (newVal == 2) {
        this.tabactive_2 = true;
      }
      if (newVal == 3) {
        this.tabactive_3 = true;
      }
      if (newVal == 4) {
        this.tabactive_4 = true;
      }
      if (newVal == 5) {
        this.tabactive_5 = true;
      }
      if (newVal == 6) {
        this.tabactive_6 = true;
      }
      if (newVal == 7) {
        this.tabactive_7 = true;
      }
      if (newVal == 8) {
        this.tabactive_8 = true;
      }
      if (newVal == 9) {
        this.tabactive_9 = true;
      }
      if (newVal == 10) {
        this.tabactive_10 = true;
      }
      if (newVal == 11) {
        this.tabactive_11 = true;
      }
      if (newVal == 12) {
        this.tabactive_12 = true;
      }
      if (newVal == 13) {
        this.tabactive_13 = true;
      }
      if (newVal == 14) {
        this.tabactive_14 = true;
      }
    },
    getTotalJenisPenduduk() {
      this.$axios.get("/v1/sensus/jiwa-kk-pria-wanita").then((response) => {
        let result = response.data;
        this.total_pria = result.pria.semua;
        this.total_perempuan = result.wanita.semua;
        this.total_jiwa = result.jiwa.semua;
        this.total_kk = result.kartu_keluarga.semua;
      });
    },
    getDataPartisipasiSekolah() {
      this.$axios.get("/v1/sensus/partisipasi-sekolah").then((response) => {
        let result = response.data;
        this.partisipasi_sekolah = result;
      });
    },
    showModalDetail(district_id) {
      this.$axios
        .get("/v1/sensus/city/kependudukan/" + district_id)
        .then((response) => {
          let result = response.data;
          this.data_district = result;
          this.$bvModal.show("modal_detail");
        });
    },
    persebaranOAP() {
      this.$axios.get("/v1/sensus/persebaran-oap").then((response) => {
        this.dataOAP = response.data;
        //  console.log(response.data)
      });
    },
    routerKabuaten(id) {
      this.$router.push("/data-kabupaten/" + id);
    },
    routerDesa(id, name) {
      this.$router.push("/data-desa/" + id);
      this.$store.commit("DISTRICT_STATE", name);
    },
    getDataProvinsi() {
      this.$axios.get("/v1/sensus/kependudukan-provinsi").then((response) => {
        let result = response.data;
        this.data_provinsi = result;
      });
    },
  },
};
</script>
